<template>
  <v-row>
    <v-col cols="10">
      <v-card max-width="600" class="mx-auto cardColor">
        <v-list subheader two-line>
          <v-list-item v-for="folder in folders" :key="folder.title">
            <v-list-item-avatar>
              <v-icon class="grey lighten-1" dark> mdi-folder </v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-text="folder.title" />

              <v-list-item-subtitle v-text="folder.subtitle" />
            </v-list-item-content>

            <v-list-item-action>
              <v-btn icon>
                <v-icon color="grey lighten-1">mdi-information</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>

          <v-divider inset />

          <v-col cols="12">
            <v-file-input show-size counter multiple label="Subir archivo" />
          </v-col>
          <v-btn
            :loading="loading3"
            :disabled="loading3"
            color="blue-grey"
            class="ma-2 white--text"
            @click="loader = 'loading3'"
          >
            Subir archivo
            <v-icon right dark> mdi-cloud-upload </v-icon>
          </v-btn>
        </v-list>
      </v-card>
    </v-col>
  </v-row>
</template>
<style>
  .cardColor {
    background-color: rgba(255, 255, 255) !important;
    border-color: white !important;
  }
</style>
<script>
  export default {
    data: () => ({
      loading3: false,
      files: [
        {
          color: 'blue',
          icon: 'mdi-clipboard-text',
          subtitle: 'Jan 20, 2014',
          title: 'Vacation itinerary',
        },
        {
          color: 'amber',
          icon: 'mdi-gesture-tap-button',
          subtitle: 'Jan 10, 2014',
          title: 'Kitchen remodel',
        },
      ],
      folders: [
        {
          subtitle: 'XLSX 2MB',
          title: 'Matriz de apoyo',
        },
        {
          subtitle: 'PDF 1MB',
          title: 'Manual de usuario',
        },
      ],
    }),
  }
</script>
