<template>
  <div class="vuse-content-wrapper">
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-stepper v-model="e6" vertical height="250">
            <v-stepper-step :complete="e6 > 1" editable step="1">
              Datos del cursos
            </v-stepper-step>

            <v-stepper-content step="1">
              <v-btn color="primary" @click="e6 = 2"> Continuar </v-btn>
            </v-stepper-content>

            <v-stepper-step :complete="e6 > 2" editable step="2">
              Pre-requisitos
            </v-stepper-step>

            <v-stepper-content step="2">
              <requirement-course />

              <v-btn color="primary" @click="e6 = 3"> Continuar </v-btn>
              <v-btn text> Atras </v-btn>
            </v-stepper-content>

            <v-stepper-step :complete="e6 > 3" editable step="3">
              Lecciones
            </v-stepper-step>

            <v-stepper-content step="3">
              <lessons-list />

              <v-btn color="primary" @click="e6 = 4"> Continuar </v-btn>
              <v-btn text> Atras </v-btn>
            </v-stepper-content>

            <v-stepper-step step="4"> Examen </v-stepper-step>
            <v-stepper-content step="4">
              <certificate-course />

              <v-btn color="primary" @click="e6 = 1"> Continuar </v-btn>
              <v-btn text> Atras </v-btn>
            </v-stepper-content>
          </v-stepper>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
  import RequirementCourse from '@/components/CreateCourse/RequirementCourse'
  import LessonsList from '@/components/CreateCourse/LessonsList'
  import CertificateCourse from '@/components/CreateCourse/CertificateCourse'

  export default {
    components: {
      RequirementCourse,
      LessonsList,
      CertificateCourse,
    },
    data() {
      return {
        e6: 1,
      }
    },
  }
</script>
