<template>
  <v-stepper
    v-model="e1"
    class="pb-2"
  >
    <v-stepper-header>
      <v-stepper-step
        :complete="e1 > 1"
        editable
        step="1"
      >
        Datos de la leccion
      </v-stepper-step>

      <v-divider/>

      <v-stepper-step
        :complete="e1 > 2"
        editable
        step="2"
      >
        Material de apoyo
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
          <v-row>

        <v-col cols="11">
        <v-card>
            <v-card-text>
            <v-container>
                <v-row>
                <v-col cols="12">
                    <v-text-field
                    label="Nombre *"
                    required
                    />
                </v-col>
                <v-col cols="12">
                    <v-textarea
                    color="teal"
                    >
                    <template v-slot:label>
                        <div>
                        Descripción <small>*</small>
                        </div>
                    </template>
                    </v-textarea>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                >
                    <v-select
                    :items="['Video', 'Texto', 'Audio', 'Presentacion']"
                    label="Formato *"
                    required
                    />
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                >
                    <v-select
                    :items="['5 Minutos', '10 Minutos', '15 Minutos', '20 Minutos']"
                    label="Duracion *"
                    required
                    />
                </v-col>
                <v-col cols="12">
                    <v-text-field
                    label="Enlace del video *"
                    required
                    />
                </v-col>
                </v-row>
            </v-container>
            <small>*indicates required field</small>
            </v-card-text>
        </v-card>
        </v-col>
        </v-row>

        <v-btn
          color="primary"
          @click="e1 = 2"
        >
          Siguiente
        </v-btn>

        <v-btn text>
          Atras
        </v-btn>
        <br>
      </v-stepper-content>

      <v-stepper-content step="2">

        <files-list />

        <v-btn
          color="primary"
          @click="e1 = 3"
        >
          Siguiente
        </v-btn>

        <v-btn text>
          Atras
        </v-btn>
      </v-stepper-content>

    </v-stepper-items>
  </v-stepper>
</template>
<script>
  import FilesList from './FilesList';

  export default {
    components: {
        FilesList
    },
    data () {
      return {
        e1: 1,
      }
    },
  }
</script>
