<template>
  <v-row>
    <v-col cols="6">
      <v-row justify="center">
        <v-dialog v-model="dialog" max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on"> Nueva pregunta </v-btn>
          </template>
          <v-card>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field label="Pregunta*" required />
                  </v-col>
                  <v-col cols="9">
                    <v-text-field label="Respuesta 1 *" required />
                  </v-col>
                  <v-col cols="3">
                    <v-checkbox
                      label="Correcto"
                      color="primary"
                      value="Correcto"
                      hide-details
                    />
                  </v-col>
                  <v-col cols="9">
                    <v-text-field label="Respuesta 2 *" required />
                  </v-col>
                  <v-col cols="3">
                    <v-checkbox
                      label="Correcto"
                      color="primary"
                      value="Correcto"
                      hide-details
                    />
                  </v-col>
                  <v-col cols="9">
                    <v-text-field label="Respuesta 3 *" required />
                  </v-col>
                  <v-col cols="3">
                    <v-checkbox
                      label="Correcto"
                      color="primary"
                      value="Correcto"
                      hide-details
                    />
                  </v-col>
                </v-row>
              </v-container>
              <small>*Marque la respuesta correcta</small>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn color="blue darken-1" text @click="dialog = false"> Cerrar </v-btn>
              <v-btn color="blue darken-1" text @click="dialog = false"> Guardar </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-col>

    <v-col cols="6">
      <v-expansion-panels class="mt-5">
        <v-expansion-panel>
          <v-expansion-panel-header disable-icon-rotate>
            Pregunta 1
            <template v-slot:actions>
              <v-icon color="error"> mdi-delete </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            Respuesta 1
            <br /><br />
            Respuesta 2
            <br /><br />
            Respuesta 3 (Respuesta correcta)
            <br /><br />
            Respuesta 4
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>
<script>
  export default {
    props: {
      title: String,
      subtitle: String,
      leftBtnTxt: String,
      rightBtnTxt: String,
      leftBtnEvent: Function,
      rightBtnEvent: Function,
    },
    data() {
      return {
        dialog: false,
        satisfactionEmojis: ['😭', '😢', '☹️', '🙁', '😐', '🙂', '😊', '😁', '😄', '😍'],
        slider: 10,
      }
    },
  }
</script>
