<template>
  <v-item-group active-class="primary">
    <v-container>
      <v-row>
        <div class="col-6">
          <draggable
            :list="list"
            :disabled="!enabled"
            class="list-group"
            ghost-class="ghost"
            :move="checkMove"
            @start="dragging = true"
            @end="dragging = false"
          >
            <div class="list-group-item" v-for="element in list" :key="element.name">
              {{ element.name }}
            </div>
          </draggable>
        </div>
      </v-row>
    </v-container>
  </v-item-group>
</template>
<script>
  import draggable from 'vuedraggable'
  let id = 1
  export default {
    components: {
      draggable,
    },
    props: {
      title: String,
      subtitle: String,
      leftBtnTxt: String,
      rightBtnTxt: String,
      leftBtnEvent: Function,
      rightBtnEvent: Function,
    },
    data() {
      return {
        enabled: true,
        list: [
          { name: 'Curso 1', id: 0 },
          { name: 'Curso 2', id: 1 },
          { name: 'Curso 3', id: 2 },
        ],
        dragging: false,
      }
    },
    computed: {
      draggingInfo() {
        return this.dragging ? 'under drag' : ''
      },
    },
    methods: {
      add: function () {
        this.list.push({ name: 'Juan ' + id, id: id++ })
      },
      replace: function () {
        this.list = [{ name: 'Edgard', id: id++ }]
      },
      checkMove: function (e) {},
    },
  }
</script>
<style scoped>
  .buttons {
    margin-top: 35px;
  }
  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }
  .list-group-item {
    padding: 5px;
    border: 1px solid #bababa;
  }
</style>
